<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title class="d-flex justify-space-between">
          <v-btn
            class="ma-2"
            color="secondary"
            @click="$router.back()"
            outlined
            dark
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>

        <v-card-title class="pl-6">Import Students</v-card-title>

        <div class="px-6">
          <div>Student imports will be added to customer:</div>
          <h6>{{ description }}</h6>
        </div>

        <v-divider></v-divider>

        <v-card-text class="px-6">
          <div>Please check the following before uploading your CSV file:</div>
          <ul>
            <li>Import students in a csv file separated by ";"</li>
            <li>Filetype needs to be CSV and not XLS(s)</li>
            <li>
              Columns will be separated by
              (firstname,lastname,email,studentnumber,function)
            </li>
            <li>
              Example row:
              <i>john;deer;johndeer@domain.tld;12345_1;student</i>
            </li>
          </ul>
          <v-btn class="my-3" @click="downloadSample">Download example</v-btn>
        </v-card-text>

        <div class="px-6">
          <v-form ref="form">
            <v-file-input
              v-model="uploaded"
              @change="save"
              label="CSV File"
              :rules="[rule.required]"
              :loading="loading"
              accept="text/csv"
              prepend-icon="mdi-file-outline"
              show-size
              outlined
            ></v-file-input>
          </v-form>
        </div>
      </base-card>
      <template>
        <v-row justify="center">
          <v-dialog max-width="1000px" v-model="actionDialog" persistent>
            <v-card>
              <div class="d-flex">
                <v-card-title v-if="hasError">Import Failed</v-card-title>
                <v-card-title v-else>Imported New Students</v-card-title>
              </div>
              <div
                class="pa-5"
                v-if="students | $options.filters.hasValue(students)"
              >
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Row</th>
                        <th>Username</th>
                        <th>Password</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Student Number</th>
                        <th>Customer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="(
                          { row, details, errors, student }, index
                        ) in students"
                      >
                        <tr
                          :key="index"
                          :class="{
                            'no-border':
                              errors | $options.filters.hasValue(errors),
                          }"
                        >
                          <td>{{ row }}</td>
                          <td>{{ student ? student.username : "" }}</td>
                          <td>{{ student ? student.password : "" }}</td>
                          <td>
                            {{ details.first_name }} {{ details.last_name }}
                            <span v-if="errors?.first_name"></span>
                            <span v-if="errors?.last_name"></span>
                          </td>
                          <td>
                            {{ details.email
                            }}<span v-if="errors?.email"></span>
                          </td>
                          <td>
                            {{ details.number
                            }}<span v-if="errors?.number"></span>
                          </td>
                          <td>{{ description }}</td>
                          <span v-if="errors?.function"></span>
                        </tr>
                        <tr v-if="errors | $options.filters.hasValue(errors)">
                          <td class="errors" colspan="7">
                            <li
                              v-for="(
                                error, errorIndex
                              ) in $options.filters.collected(errors)"
                              :key="errorIndex"
                            >
                              {{ error }}
                            </li>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-radio-group
                  v-if="imported && !hasError"
                  v-model="action"
                  @change="sendCredentials"
                  column
                  hide-details
                >
                  <template v-slot:label>
                    <div class="mb-2">
                      Choose action for sending login details.
                    </div>
                  </template>
                  <v-radio
                    value="mail"
                    v-if="!action || action === 'mail'"
                    :disabled="!!action"
                  >
                    <template v-slot:label>
                      <span v-if="action">Sending mail to students...</span>
                      <span v-else>Mail login details to students</span>
                    </template>
                  </v-radio>
                  <v-radio
                    value="manual"
                    v-if="!action || action === 'manual'"
                    :disabled="!!action"
                  >
                    <template v-slot:label>
                      <span v-if="action"
                        >Downloading students credential...</span
                      >
                      <span v-else>Send login details manually</span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="loading || (!hasError && !action)"
                  :loading="loading"
                  @click="confirmAction"
                  text
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import {
  CUSTOMER_ACTION,
  FILE_ACCEPTS,
  FILE_INPUT_ICON,
} from "@/shared/constants";
import rules from "@/shared/rules";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Import Students",
  },

  data() {
    return {
      accepts: FILE_ACCEPTS,
      fileInputIcon: FILE_INPUT_ICON,
      rule: rules,
      id: _.get(this.$route, "params.id"),
      uploaded: null,
      students: [],
      hasError: false,
      imported: false,
      action: null,
      actionDialog: false,
      loading: false,
    };
  },

  methods: {
    ...mapActions(["importFromCsv", "sendMutipleStudentLoginDetails"]),
    async save() {
      this.loading = true;

      if (!this.$refs.form.validate()) {
        this.loading = false;

        return;
      }

      try {
        const formData = new FormData();
        formData.append("customerId", this.id);
        formData.append("uploaded", this.uploaded);

        const { hasError, students } = await this.importFromCsv(formData);
        this.hasError = hasError;
        this.students = students;
        this.imported = !this.hasError;
        this.actionDialog = students.length;
      } catch (error) {
        this.$store.dispatch(
          "showSnackbar",
          "Something went wrong while importing."
        );
      }

      this.loading = false;
    },

    async sendCredentials() {
      this.loading = true;

      switch (this.action) {
        case CUSTOMER_ACTION.MAIL:
          await this.sendMutipleStudentLoginDetails({ ids: this.studentIds });

          break;
        case CUSTOMER_ACTION.MANUAL:
          this.downloadCsv(
            "imported-students-credential",
            _.map(this.students, (data) => ({
              username: data.student.username,
              password: data.student.password,
              name: `${data.details.first_name} ${data.details.last_name}`,
              email: data.details.email,
              customer: this.description,
            })),
            ["Username", "Password", "Name", "Email", "Customer"]
          );

          break;
      }

      this.loading = false;
    },

    confirmAction() {
      this.actionDialog = false;
      this.$refs.form.reset();
      this.action = null;
      this.hasError = false;
      this.students = null;
    },

    async downloadSample() {
      this.downloadCsv("import-sample", [
        { sample: "John;Deer;johndeer@example.com;12345_1;Student" },
      ]);
    },

    downloadCsv(name, data, headers = null) {
      const csvRows = [];

      if (headers) {
        csvRows.push(headers.join(","));
      }

      for (const row of data) {
        csvRows.push(
          Object.keys(row)
            .map((key) => row[key])
            .join(",")
        );
      }

      const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", `${name}.csv`);
      a.click();
    },
  },
  computed: {
    ...mapGetters(["getStudentsLoading", "getCustomer"]),

    studentIds() {
      return _.map(this.students, (data) => data.student.id);
    },

    description() {
      return _.get(this.getCustomer, "description");
    },
  },
  filters: {
    hasValue: function (value) {
      return !!_.size(value);
    },
    collected: function (errors) {
      return _.chain(errors)
        .map((e) => e)
        .flatten()
        .value();
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  tr {
    &.no-border td {
      border-bottom: none !important;
    }

    td {
      position: relative;

      &.errors li {
        color: #dc3545;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 1px solid #dc3545;
      }
    }
  }
}
</style>
